import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { colors, typography, animations, mq, util } from 'src/styles'


const Wrapper = styled.div`
	height: 100vh;
	padding: 50px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const ContentWrap = styled.div`
	width: 100%;
	height: 50vh;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${ mq.mediumAndBelow } {
		height: 35vh;
	}
`

const LogoWrap = styled.div`
	width: 55%;
	margin: 0 auto;
	svg {
		width: 100%;
	}

	${ mq.mediumAndBelow } {
		width: 95%;
	}
`

const AbsLogoWrap = styled.div`
	position: absolute;
	width: ${props => props.width }%;
	top: ${props => props.top }%;
	left: ${props => props.left }%;
	${ mq.mediumAndBelow } {
		width: calc(2 * ${props => props.width }%);
	}
`

const LandingLogos = ({ className }) => {

	const { nsfLogo, risdLogo, ricaimLogo, natureLogo } = useStaticQuery(
		graphql`
			query {
				nsfLogo: file(relativePath:{eq: "images/nsf-logo.png"}) {
					publicURL
				}
				risdLogo: file(relativePath:{eq: "images/risd-logo.png"}) {
					publicURL
				}
				ricaimLogo: file(relativePath:{eq: "images/ricaim-logo.png"}) {
					publicURL
				}
				natureLogo: file(relativePath:{eq: "images/naturelab-logo.png"}) {
					publicURL
				}
			}
		`)
	return (
	  <Wrapper className={className}>
	  	<ContentWrap>
	  		<LogoWrap>
	  			<svg viewBox="0 0 700 90" fill="none">
					<mask id="path-1-inside-1" fill="white">
					<path d="M71.4315 0H61.1554L35.5904 70.6796L10.1508 0H0L31.9562 89.2267H39.35L71.4315 0ZM92.1842 89.2267V0H83.2866V89.2267H92.1842ZM177.529 20.4269L157.729 0H126.149L107.852 18.7978V39.8512L127.151 49.6261H154.847L166.125 55.516V66.2934L153.594 79.2012H130.284L113.241 61.5313L106.223 68.7998L126.149 89.2267H157.729L175.9 70.4289V49.3754L157.228 39.6006H129.407L117.627 33.5853V22.9333L130.284 10.0255H153.594L170.637 27.6954L177.529 20.4269ZM237.278 49.2501V39.9766H186.148V49.2501H237.278ZM315.107 89.2267L283.026 0H275.632L243.676 89.2267H253.827L263.226 63.4111H295.432L304.831 89.2267H315.107ZM291.923 53.3856H266.86L279.266 18.5471L291.923 53.3856ZM372.578 49.2501V39.9766H321.448V49.2501H372.578ZM448.026 10.0255V0H380.103V10.0255H409.177V89.2267H418.952V10.0255H448.026ZM529.086 89.2267V0H519.311V39.6006H470.813V0H461.038V89.2267H470.813V49.6261H519.311V89.2267H529.086ZM614.516 70.4289V18.7978L596.219 0H564.765L546.468 18.7978V70.4289L564.765 89.2267H596.219L614.516 70.4289ZM604.741 66.2934L592.209 79.2012H568.775L556.243 66.2934V22.9333L568.775 10.0255H592.209L604.741 22.9333V66.2934ZM700 89.2267V0H690.225V69.5517L639.722 0H631.952V89.2267H641.727V19.675L692.23 89.2267H700Z"/>
					</mask>
					<path d="M71.4315 0H61.1554L35.5904 70.6796L10.1508 0H0L31.9562 89.2267H39.35L71.4315 0ZM92.1842 89.2267V0H83.2866V89.2267H92.1842ZM177.529 20.4269L157.729 0H126.149L107.852 18.7978V39.8512L127.151 49.6261H154.847L166.125 55.516V66.2934L153.594 79.2012H130.284L113.241 61.5313L106.223 68.7998L126.149 89.2267H157.729L175.9 70.4289V49.3754L157.228 39.6006H129.407L117.627 33.5853V22.9333L130.284 10.0255H153.594L170.637 27.6954L177.529 20.4269ZM237.278 49.2501V39.9766H186.148V49.2501H237.278ZM315.107 89.2267L283.026 0H275.632L243.676 89.2267H253.827L263.226 63.4111H295.432L304.831 89.2267H315.107ZM291.923 53.3856H266.86L279.266 18.5471L291.923 53.3856ZM372.578 49.2501V39.9766H321.448V49.2501H372.578ZM448.026 10.0255V0H380.103V10.0255H409.177V89.2267H418.952V10.0255H448.026ZM529.086 89.2267V0H519.311V39.6006H470.813V0H461.038V89.2267H470.813V49.6261H519.311V89.2267H529.086ZM614.516 70.4289V18.7978L596.219 0H564.765L546.468 18.7978V70.4289L564.765 89.2267H596.219L614.516 70.4289ZM604.741 66.2934L592.209 79.2012H568.775L556.243 66.2934V22.9333L568.775 10.0255H592.209L604.741 22.9333V66.2934ZM700 89.2267V0H690.225V69.5517L639.722 0H631.952V89.2267H641.727V19.675L692.23 89.2267H700Z" stroke="white" stroke-width="2" mask="url(#path-1-inside-1)"/>
					</svg>

	  		</LogoWrap>
	  		<AbsLogoWrap top={0} left={20} width={7}>
	  			<img src={nsfLogo.publicURL} />
	  		</AbsLogoWrap>

	  		<AbsLogoWrap top={60} left={80} width={5}>
	  			<img src={risdLogo.publicURL} />
	  		</AbsLogoWrap>

	  		<AbsLogoWrap top={70} left={7} width={5}>
	  			<img src={ricaimLogo.publicURL} />
	  		</AbsLogoWrap>

	  		<AbsLogoWrap top={90} left={55} width={9}>
	  			<img src={natureLogo.publicURL} />
	  		</AbsLogoWrap>
	  	</ContentWrap>
	  </Wrapper>
)}

export default LandingLogos
