import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled from '@emotion/styled'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import StaticHeader from 'src/components/StaticHeader'
import PwProtectionLogin from 'src/components/PwProtectionLogin'

import MenuListener from 'src/components/MenuListener'

import Footer from 'src/components/Footer'
import Container from 'src/components/Container'
import LandingLogos from 'src/components/LandingLogos'

import { colors, typography, animations, mq, util } from 'src/styles'


const propTypes = {
	data: PropTypes.object.isRequired,
}

const PageWrapper = styled.div`
	position: relative;

	${ ({ pageType }) => pageType === 'about' ? `
	` : pageType === 'apply' ? `
			padding-top: 95px;
	` : pageType === 'contact' ? `
			padding-top: 95px;
	` : pageType === 'vis-a-thon-2020' ? `
	` : pageType === 'portal' ? `
	` :`
	`}


	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		transition: transform 0.75s ease;
		// background-attachment: fixed;
		${ mq.mediumAndBelow } {
			background-repeat: repeat-y;
		}
			background-position: top;
			background-size: contain;
		
		${ ({ pageType }) => pageType === 'about' ? `
			background-image: url(/about-bg.jpg);
			${ mq.mediumAndBelow } {
				background-image: url(/mobile-about-bg.jpg);
			}
		` : pageType === 'apply' ? `
			background-image: url(/apply-bg.jpg);
			${ mq.mediumAndBelow } {
				background-image: url(/mobile-apply-bg.jpg);
			}
			
		` : pageType === 'contact' ? `
			background-image: url(/contact-bg.jpg);
			${ mq.mediumAndBelow } {
				background-image: url(/mobile-contact-bg.jpg);
			}
		` : pageType === 'vis-a-thon-2020' ? `
			background-image: url(/projects-bg.jpg);
			${ mq.mediumAndBelow } {
				background-image: url(/mobile-projects-bg.jpg);
			}
		` : pageType === 'portal' ? `
			background: black;
		` : pageType === 'events' ? `
			background-image: url(/events-bg.jpg);
			${ mq.mediumAndBelow } {
				background-image: url(/mobile-events-bg.jpg);
			}
		` : `
			background-image: url(/landing-bg.jpg);
			${ mq.mediumAndBelow } {
				background-image: url(/mobile-landing-bg.jpg);
			}
			background-size: cover;
		`}
		
		${ ({ hover }) => hover === 'left' ? `
			transform: translate3d(-8vw, 0, 0);
		` : hover === 'right' ? `
			transform: translate3d(8vw, 0, 0);
		` : ``}
	}



`

const PageContainer = styled(Container)`
	// background: black;
	min-height: 100vh;
	
`


const NsfFooter = styled.div`
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 50px;
	padding-bottom: 100px;
	padding-left: 105px;
	padding-right: 105px;
	background: transparent;
	margin-top: 100px;
	p {
		font-size: 14px;
		margin-top: 1.2em;
	}
	${ mq.mediumAndBelow } {
		flex-direction: column;
		padding-left: 20px;
		padding-right: 20px;
		p {
			text-align: center;
		}
	}
`

const FooterLogoWrap = styled.div`
	width: 10%;
	max-width: 75px;
	margin-right: 20px;
	${ mq.mediumAndBelow } {
		width: 20%;
		margin-right: 0;
	}
`

class PageTemplate extends React.Component {

	state = {
    pwProtected: false,
    enteredPassword: "",
    pwError: false,
    hoverShift: false
  }

  componentDidMount = () => {
    const page = this.props.data.allContentfulPage.edges[0].node
    const site = this.props.data.allContentfulSiteSettings.edges[0].node
    const { password } = site
    if (password) {
      this.setState({
        pwProtected: true,
        password: password,
      })
    }
  }

  unlockPage = () => {
    const { enteredPassword, password } = this.state
    const page = this.props.data.allContentfulPage.edges[0].node
    if (enteredPassword === password) {
      console.log("unlock")
      this.setState({ pwProtected: false })
    } else {
      console.log("nope!")
      this.setState({ pwError: true })
    }
  }

  handleHoverState = (hover, hoverItem) => {
  	if ( hover === true ) {
	  	if ( hoverItem === 'west') {
	  		this.setState({hoverShift: 'right'})
	  	} else if ( hoverItem === 'east' ) {
	  		this.setState({hoverShift: 'left'})
	  	}
  	} else {
  		this.setState({hoverShift: false})
  	}
  }

  handleInput = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  

	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges[0].node
		const page = this.props.data.allContentfulPage.edges[0].node
		const { sections } = page
		const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
		const seo = page.seo
		const { pwProtected, enteredPassword, pwError, password } = this.state
		// password protection logic

		console.log("::::::", page)
    if (pwProtected && password && page.slug === 'portal') {
      return (
        <div>
          
          <PwProtectionLogin
            handleInput={this.handleInput}
            unlockPage={this.unlockPage}
            enteredPassword={enteredPassword}
            error={pwError}
          />
          <h4 style={{ position: "fixed", bottom: 0, left: 0 }}></h4>
        </div>
      )
    }

		return (
			<PageWrapper pageType={page.slug} hover={this.state.hoverShift}>
				<SEO
					title={page.title}
					description={seo.description && seo.description.description}
					siteSettings={site}
					keywords={seo.keywords}
					shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
				/>
			{/*
				<StaticHeader
          navigation={site.navigation}
          handleHoverState={this.handleHoverState}
				/>
			*/}
			<PageContainer className={page.slug}>
				{ page.slug === '/' && page.title === 'Home'? ( <LandingLogos />) : false }
				{sections && sections.map((section, index) => {
					const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
					const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
					const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
					const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
					const lastSection = sections.length === index + 1
					return (
						<ComponentRenderer
							prevTheme={prevFullWidth ? false : prevTheme}
							nextTheme={nextFullWidth ? false : nextTheme}
							isFirstSection={index === 0}
							isLastSection={lastSection}
							key={section.id + index}
							item={section}
							index={index}
						/>
					)
				})}
				</PageContainer>
			{/*
				<Footer {...site} />
			*/}	

			{ page.slug.length > 0 && page.slug != 'portal' && page.slug != '/' ? (
				<NsfFooter>
					<FooterLogoWrap>
						<img src={'/nsf-logo.png'} />
					</FooterLogoWrap>
					<p>This material is based upon work supported in part by the National Science Foundation under EPSCoR Cooperative Agreement #OIA-1655221. <br/> Any opinions, findings, and conclusions or recommendations expressed in this material are those of the author(s) and do not necessarily reflect the views of the National Science Foundation.</p>
				</NsfFooter>
			) : false}
			</PageWrapper>
		)
	}
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					title
					slug
					sections {
						...Accordion
						...BlockText
						...GeneralContentBlock
						...ImageGroup
						...FullMedia
						...TwoUp
						...ProjectIndexList
						...PortalIndexGroup
					}
					seo {
						description {
							description
						}
						shareImage {
							file {
								url
							}
						}
					}
				}
			}
    }
	}
`

export default PageTemplate
